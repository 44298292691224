
export default {
  props: {
    type: {
      type: String,
      default: '',
      required: false,
    }
  },

  data() {
    return {
      languageSelected: {
        label: 'English',
        value: 'en'
      },
      languageOptions: this.$i18n.locales.filter((item) => item.code === 'en' || item.code === 'ch').map(item => {
        return {
          label: item.name,
          value: item.code
        }
      })
      // languageOptions: this.$i18n.locales.map(item => {
      //   return {
      //     label: item.name,
      //     value: item.code
      //   }
      // })

    }
  },

  computed: {
    languages() {
      return this.$i18n.locales.filter(item => item.code !== 'ch')
    },
  },

  methods: {
    onChangeLanguage(locale) {
      this.$i18n.setLocale(locale)
    },
  },
}
